import React, { useState, useEffect } from 'react';
import axios from 'axios';

function ChatComponent() {
  const [websocket, setWebsocket] = useState(null);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [isEnabled, setIsEnabled] = useState(false);
  const [goal, setGoal] = useState("");
  const [response, setResponse] = useState("");
  const [maxTurns, setMaxTurns] = useState(10);
  const [teamMembers, setTeamMembers] = useState([]);
  // const availableAgents = ["HumanInput Agent", "API Invoke Agent", "Open Interpreter", "Mini Agent", "RAG Agent", "Market Agent", "Financial Agent"];
  const availableAgents = ["HumanInput Agent", "API Invoke Agent", "Open Interpreter", "Market Agent", "Financial Agent"];

  useEffect(() => {
    // const ws = new WebSocket('ws://127.0.0.1:7788/ws2/frontend_client');
    // const ws = new WebSocket('ws://50.19.136.32:7788/ws2/frontend_client');
    const ws = new WebSocket('wss://mnn.server.questnx.com/ws2/frontend_client');
    ws.onopen = () => {
      console.log('Connected to the server.');
      setMessages(prev => [...prev, 'Connected to the server.']);
    };
    ws.onmessage = event => {
      console.log('Message from server:', event.data);
      setMessages(prev => [...prev, `Message from server: ${event.data}`]);
      setIsEnabled(true);
    };
    ws.onclose = () => {
      console.log('Disconnected from server.');
      setMessages(prev => [...prev, 'Disconnected from server.']);
      setIsEnabled(false);
    };

    setWebsocket(ws);
    return () => {
      ws.close();
    };
  }, []);

  const sendMessage = () => {
    if (websocket && input) {
      websocket.send(input);
      setMessages(prev => [...prev, `Message sent: ${input}`]);
      setInput("");
      setIsEnabled(false);
    }
  };

  const launchGoal = async () => {
    try {
      // const response = await axios.post("http://127.0.0.1:5050/launch_goal", {
      const response = await axios.post("https://mnn.agent.questnx.com/launch_goal", {
        goal: goal,
        max_turns: maxTurns,
        team_member_names: teamMembers.length > 0 ? teamMembers : null
      });
      setResponse(JSON.stringify(response.data, null, 2));
      setMessages(prev => [...prev, `Goal launched: ${goal}`]);
    } catch (error) {
      console.error("Error launching goal:", error);
      setResponse("Error launching goal");
    }
  };

  const handleTeamMemberChange = (agent) => {
    setTeamMembers(prev => 
      prev.includes(agent) 
        ? prev.filter(a => a !== agent) 
        : [...prev, agent]
    );
  };

  return (
    <div style={{ width: '400px', marginLeft: '10px' }}>
      <h1>Launch Goal</h1>
      <textarea
        value={goal}
        onChange={e => setGoal(e.target.value)}
        placeholder="Enter your goal here"
        rows={5}
      />
      <div>
        <label>
          Max Turns:
          <input
            type="number"
            value={maxTurns}
            onChange={e => setMaxTurns(Number(e.target.value))}
            min="4"
          />
        </label>
      </div>
      <div>
        <p>Select Team Members:</p>
        {availableAgents.map(agent => (
          <label key={agent}>
            <input
              type="checkbox"
              checked={teamMembers.includes(agent)}
              onChange={() => handleTeamMemberChange(agent)}
            />
            {agent}
          </label>
        ))}
      </div>
      <button onClick={launchGoal}>Launch Goal</button>
      <pre>{response}</pre>
      <hr />

      <h1>Human Feedback</h1>
      {messages.map((msg, index) => (
        <p key={index}>{msg}</p>
      ))}
      <input
        type="text"
        value={input}
        onChange={e => setInput(e.target.value)}
        placeholder="Enter response here"
        disabled={!isEnabled}
      />
      <button onClick={sendMessage} disabled={!isEnabled}>
        Send
      </button>
    </div>
  );
}




export default ChatComponent;